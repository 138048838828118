<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">

      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='mailListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>

              <!-- ID -->
              <div>
                <div class="md-form mb-0">
                  <input id="mail_id" name="mail_id" ref="mail_id"
                         :title="this.$i18n.t('form.email.id')" class="md-form-control" type="text"
                         :value="filters.filMailId">
                  <label :class="{'active': filters.filMailId}">{{ $t('form.mail.id') }}</label>
                </div>
              </div>

              <h6 class="mb-0 mt-4">{{ $t('form.mail.toSendDate') }}</h6>
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div class="md-form mb-0">
                <span class="input-icon input-icon-right">
                  <i class="ti-calendar"></i>
                </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_arrival')"
                               id="to_send_date_begin" ref="to_send_date_begin" readonly
                               class="form-date md-form-control date-filter" type="text"
                               :value="$util.formatDate(filters.filPeriodoToSendDtInicio, undefined,null)">
                        <label :class="{'active': filters.filPeriodoToSendDtInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                <span class="input-icon input-icon-right">
                  <i class="ti-calendar"></i>
                </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_arrival')"
                               id="to_send_date_end" ref="to_send_date_end" readonly class="form-date md-form-control date-filter"
                               type="text" :value="$util.formatDate(filters.filPeriodoToSendDtFim, undefined, null)">
                        <label :class="{'active': filters.filPeriodoToSendDtFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                        {{ $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList"/>
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!-- Actions -->
                <div class="flexbox px-4 py-3 mb-3">
                  <div class="d-flex align-items-center">
                  </div>
                  <div class="btn-group" role="group" aria-label="false">
                    <router-link :to="{name: 'MaerskNew'}" class="btn btn-outline-light  pull-right">
                      Novo
                    </router-link>
                  </div>
                </div>
                <!-- End Actions -->

                <!-- Table -->
                <custom-vue-table
                  ref="TaxesTable"
                  http-method="get"
                  :api-url="loadEndpoint"
                  :fields="fieldsTable"
                  :sort-order="sortOrder"
                  :append-params="activeFilters"
                  track-by="id"
                >
                  <template slot="actions" slot-scope="props">
                    <div class="custom-actions">
                      <a class="btn btn-link"
                         v-bind:href="$util.mountLink('MaerskEdit', props.rowData)">
                        <i class="text-primary  ti-pencil font-20"></i>
                      </a>
                      <a class="btn btn-link"
                         v-on:click="onDelete(props.rowData)">
                        <i class="text-primary  ti-trash font-20"></i>
                      </a>
                    </div>
                  </template>

                </custom-vue-table>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomVueTable from '@/components/Table/CustomVueTable'
import FilterSideBar from '@/components/FilterSideBar'
import FilterContainer from '@/components/FilterContainer'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import TaxLogisticService from '@/services/TaxLogisticService'

export default {
  name: 'IntegrationMaerskTaxesList',
  metaInfo () {
    return {
      titleTemplate: this.$route.meta.titleText
      // titleTemplate: this.$i18n.t('sidebar.mail-status') + ' - %s'
    }
  },
  data () {
    return {
      loadEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/integration/maersk/tax',
      sortOrder: [
        { field: 'taxa', direction: 'asc' }
      ],
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processDetail: false,
      multiSort: true,
      activeFilterList: {
        id_status: null,
        to_send_date_begin: null,
        to_send_date_end: null,
        mail_type: null,
        mail_id: null
      },
      filter: {},
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    FilterSideBar,
    CustomVueTable,
    Loading,
    FilterContainer
  },
  // Apply filters to local filter
  beforeMount () {
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('.date-filter').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    buildFilters () {
    },
    handleLoadError (response) {
    },
    // Loader
    applyFilter () {
      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.TaxesTable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.TaxesTable.refresh()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatUser (value) {
      if (!value) {
        return ' - '
      }

      let position = value.indexOf('@')

      return value.substring(0, position)
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
      // this.showModal(row.data)
    },
    onDelete (item) {
      var _this = this
      _this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja excluir.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.delete(item)).then((result) => {
        if (result.hasOwnProperty('value')) {
          if (result.value) {
            _this.$alertSwal.toast('Registro excluído com sucesso!')
          } else {
            _this.$alertSwal.toast('Não foi possível excluir o registro!', 'error')
          }
          this.$refs.TaxesTable.refresh()
        }
      })
    },
    async delete (item) {
      let _this = this
      _this.isLoading = true
      await TaxLogisticService.deleteTaxa(item.id).then(res => {
        if (res.httpCode === 200 && res.data.status === 'ok') {
          _this.isLoading = false
        } else {
          _this.isLoading = false
        }
      }).catch(() => {
        _this.isLoading = false
      })
    }
  },
  computed: {
    fieldsTable () {
      return [
        {
          name: 'taxa',
          title: 'Taxa',
          sortField: 'taxa',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'taxa_portal',
          title: 'Taxa Portal',
          sortField: 'taxa_portal',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    },
    activeFilters () {
      return this.filters
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return this.$store.getters.aplliedFilters || null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .btn-padding {
    padding: 0.55rem 0.30rem
  }
</style>
